<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url-params="{ distributeType: '0' }"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #scoreLevel="{ text }">
          <span class="score-level">{{ scoreLevel[text] }}</span>
        </template>
      </grid>
    </div>
    <a-modal
      title="自定义分发"
      :visible="visible"
      :footer="null"
      centered
      width="880px"
      @cancel="visible = false"
    >
      <div>
        <search-form :items="itemsModal" @fnSearch="handleFnSearchModal" />
        <grid
          ref="gridRefModal"
          :allow-selection="{ type: 'radio' }"
          :columns="columnsModal"
          :url-params="{ marketingType: 2 }"
          :code="$route.path"
          :url="urlModal"
          :search="searchModal"
          :btn-operation="null"
          :scroll="{ x: 800, y: gridHeightModal }"
        >
        </grid>
        <a-row type="flex" justify="space-between" align="middle" class="mt-24">
          <a-col>您已经选择了{{ length }}条商机，请分发给指定营销人员</a-col>
          <a-col>
            <a-space>
              <a-button @click="visible = false">取消</a-button>
              <a-button type="primary" @click="handleFnBatch">分发</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
import dictionaryApi from '@/api/dictionary.js';
import { message, Modal, Row, Col, Button, Space } from 'ant-design-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    // ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const gridRefModal = ref();
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      length: 0,
      gridSelectedRowKeys: [],
    });
    return {
      gridRef,
      gridRefModal,
      ...toRefs(state),
      scoreLevel: { high: '高分', middle: '中分', low: '低分' },
      items: [
        { key: 'contactName', label: '商机姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'company', label: '公司' },
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          showSearch: true,
          filterOption: 'label',
          url: distributionApi.clueUrl,
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { label: '全部', value: '' },
            { label: '高', value: 'high' },
            { label: '中', value: 'middle' },
          ],
        },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          showSearch: true,
          url: dictionaryApi.cityUrl,
        },
        // {
        //   key: 'industryLv1Code',
        //   label: '行业',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   showSearch: true,
        //   url: dictionaryApi.industryLv1CodeUrl,
        // },
        { key: 'industryLv1Code', label: '行业' },
      ],
      itemsModal: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
      ],
      columns: [
        {
          dataIndex: 'contactName',
          title: '商机姓名',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          slots: { customRender: 'scoreLevel' },
        },
      ],
      columnsModal: [
        { dataIndex: 'userName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 120, ellipsis: true },
        { dataIndex: 'companyName', title: '公司', ellipsis: true },
      ],
      btnOperation: [
        // {
        //   type: 'custom',
        //   label: '自定义分发',
        //   btnType: 'primary',
        //   permission: 'distribution:custom',
        //   fnClick: selectedRowKeys => {
        //     const length = selectedRowKeys.length;
        //     if (length) {
        //       state.length = length;
        //       state.gridSelectedRowKeys = selectedRowKeys;
        //       state.visible = true;
        //     } else {
        //       message.info('请先选择数据');
        //     }
        //   },
        // },
      ],
      options: [
        {
          type: 'update',
          permission: 'distribution:update',
          fnClick: () => {},
        },
        {
          type: 'delete',
          permission: 'distribution:delete',
          fnClick: () => {},
        },
      ],
      urlModal: distributionApi.marketingUserPageUrl,
      handleFnSearchModal: value => {
        const temp = Object.assign({}, value);
        state.searchModal = temp;
      },
      handleFnBatch: () => {
        console.log(gridRefModal.value.selectedRows);
        const length = gridRefModal.value.selectedRows.length;
        if (length) {
          distributionApi
            .customDistribute('', {
              entry: 2,
              ids: state.gridSelectedRowKeys,
              promoteUserId: gridRefModal.value.selectedRows[0].id,
            })
            .then(res => {
              if (res) {
                state.visible = false;
                const { invalid, total, valid } = res;
                Modal.info({
                  title: `本次分发商机${total}条，其中有效分发${valid}条，去重${invalid}条`,
                  centered: true,
                });
                gridRef.value.refreshGrid();
              }
            });
        } else {
          message.info('请先选择推广人员');
        }
      },
      url: distributionApi.unDistributePage,
      gridHeight: document.body.clientHeight - 457,
      gridHeightModal: document.body.clientHeight - 416,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 161px);
//  overflow-y: auto;
//}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
</style>
